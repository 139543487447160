/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from "react";
import reduxDialog, { closeDialog } from "redux-reactstrap-modal";
import { connect } from "react-redux";
import { compose } from "recompose";
import { bindActionCreators } from "redux";
import { INSTRUMENTS, LOTTERY_LIST, STRING, PAYMENT_METHOD } from "../config";
import _, { startCase, toUpper } from "lodash";
import { withRouter } from "react-router-dom";
import {
  subscribe,
  getPackage,
  getPackagePrice,
  subscribeWithWallet,
  getCommonWalletInfo,
  setNextEventLocalStorage,
  clearNextEventLocalStorage,
  clearNextEvent,
  getPaymentSource,
  setPaymentMethodToLocalStorage,
  getPaymentMethodFromLocalStorage
} from "../actions/index";
import { registerCommonWallet } from "../actions/wallet";
import { MODALS, WEEK_DAYS, EVENTS } from "../config";
import { translate } from "react-switch-lang";
import Wizad from "../components/Wizad";
// import '../styles/css/subscription.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import LoadingBar from "react-redux-loading-bar";
import { MINIMUM_TOPUP_FEE } from "../config";
import { dataPush } from "../utils/piwikDataLayerPush";
import {
  FEE,
} from "../config/";

class SubModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 1,
      loadEditText: false,
      currentStep: 1,
      email: "",
      username: "",
      password: "",
      mobile: false,
      wallet: false,
      both: false,
      dayCount: 0,
      periodData: null, //'',
      disableSubscription: false,
      disableSubmit: false,
      isCompartmentCharge: 0,
      //isConfirmed: false
    };
    this.updateInput = this.updateInput.bind(this);
    this.closeSubModal = this.closeSubModal.bind(this);
  }

  componentWillMount() {
    const { getPaymentMethodFromLocalStorage, payment_method } = this.props;
    getPaymentMethodFromLocalStorage();
    this.setState({
      mobile: payment_method ? ((payment_method === PAYMENT_METHOD.MOBILE) ? true : false) : false,
      wallet: payment_method ? ((payment_method === PAYMENT_METHOD.WALLET) ? true : false) : false,
      both: payment_method ? ((payment_method === PAYMENT_METHOD.BOTH) ? true : false) : true,
    });
  }

  componentDidMount = () => {
    this.props.getPackage();
    this.props.getPaymentSource();
    //this.props.getCommonWalletInfo();
  };

  _next = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep >= 2 ? 3 : currentStep + 1;
    this.setState({
      currentStep: currentStep,
    });
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
      isConfirmed: false,
    });
  };

  /*
   * the functions for our button
   */
  previousButton() {
    let currentStep = this.state.currentStep;
    const {
      data: { dark_color },
      t,
    } = this.props;

    var buttonStyle = {
      border: `1px solid ${dark_color}`,
      backgroundColor: "#ffffff",
      color: dark_color,
    };

    if (currentStep !== 1) {
      return (
        <button
          className="btn btn-block mr-1"
          style={buttonStyle}
          disabled={this.state.disableSubmit || this.state.disableSubscription}
          type="button"
          onClick={this._prev}
        >
          {t("buttons.previous")}
        </button>
      );
    }
    return null;
  }

  nextButton() {
    let { currentStep, count } = this.state;
    const {
      data: { dark_color },
      t,
    } = this.props;

    var buttonStyle = {
      border: `1px solid ${dark_color}`,
      backgroundColor: dark_color,
      color: "#ffffff",
    };

    if (currentStep === 1) {
      return (
        <button
          className="btn btn-block mr-1"
          style={buttonStyle}
          disabled={count > 0 ? false : true}
          type="button"
          onClick={this._next}
        >
          {t("buttons.next")}
        </button>
      );
    } else if (currentStep === 2) {
      return (
        <button
          className="btn btn-block mr-1"
          style={buttonStyle}
          disabled={!this.state.wallet && !this.state.both}
          type="button"
          onClick={this._next}
        >
          {t("buttons.next")}
        </button>
      );
    }
    return null;
  }

  submitButton() {
    const { mobile, wallet, disableSubmit } = this.state;
    const {
      data: { dark_color },
      t,
      payment_source,
    } = this.props;

    let paymentMethod = mobile ? "MOBILE" : wallet ? "WALLET" : "BOTH";
    if (payment_source && payment_source === toUpper(INSTRUMENTS.FRIMI)) {
      paymentMethod = "WALLET";
    }
    let buttonStyle = {
      border: `1px solid ${dark_color}`,
      backgroundColor: dark_color,
      color: "#ffffff",
    };

    // if (currentStep === 3) {
    return (
      <button
        className="btn btn-block mr-1"
        id="subscribe-modal-submit-button"
        style={buttonStyle}
        // disabled={(this.state.isConfirmed === false) || disableSubmit}
        disabled={disableSubmit}
        type="button"
        onClick={() => this.handleSubmitButtonClick(paymentMethod)}
      >
        {t("wallet.submit")}
      </button>

      // <button
      //     className="btn btn-block mr-1"
      //     style={buttonStyle}
      //     disabled={!(this.state.dayCount && packagePrice.price) || disableSubmit}
      //     type="button" onClick={() => this.handleSubmitButtonClick(paymentMethod)}>
      //     {t("wallet.submit")}
      // </button>
    );
    //}
    //return null;
  }

  handleSubmitButtonClick(paymentMethod) {
    const {
      data: { package_id },
      subscribeWithWallet,
      commonWalletInfo,
      registerCommonWallet,
      payment_source,
      subscribe,
      setPaymentMethodToLocalStorage
    } = this.props;
    const { count, isCompartmentCharge } = this.state;

    this.setState({ disableSubmit: true });

    const subscribeData = {
      packageId: package_id,
      count: count,
      paymentMethod: paymentMethod,
      source:
        payment_source === toUpper(INSTRUMENTS.FRIMI)
          ? toUpper(INSTRUMENTS.FRIMI)
          : STRING.WALLET_REQUEST_TYPE_WEB,
      salesType: "SUBSCRIPTION",
      isCompartmentCharge: isCompartmentCharge,
      customerReferral: sessionStorage.getItem("referral_code"),

      // lotteryId: package_id,     //periodData.lotteryId,
      // payload: {
      //     walletPackageId: 1,     //periodData.walletPackageId,
      //     paymentMethod: paymentMethod,
      //     ticketsCount: count,
      //     source: STRING.WALLET_REQUEST_TYPE_WEB
      //     //isConfirmed: isConfirmed
      // }
    };

    //register wallet
    const data = {
      action: STRING.SUBSCRIBE_WALLET,
      ...(payment_source &&
        payment_source === toUpper(INSTRUMENTS.FRIMI) && {
          source: INSTRUMENTS.FRIMI,
        }),
    };

    if (
      commonWalletInfo &&
      commonWalletInfo.status === STRING.WALLET_ACTIVE &&
      commonWalletInfo.walletPaymentInstruments &&
      commonWalletInfo.walletPaymentInstruments.length > 0
    ) {
      if (
        payment_source &&
        payment_source === toUpper(INSTRUMENTS.FRIMI) &&
        !commonWalletInfo.walletPaymentInstruments.isDefault &&
        commonWalletInfo.walletPaymentInstruments.type === "Account" &&
        commonWalletInfo.walletPaymentInstruments.name.includes(
          startCase(INSTRUMENTS.FRIMI)
        )
      ) {
        registerCommonWallet({ ...data, purchaseRequest: subscribeData });
      } else {
        //call subcribe
        //subscribeWithWallet(subscribeData)
        subscribe(subscribeData);
      }
      //piwik pro related code segment
      let dataLayerObject = {
        tickets_count: this.state.count,
      };
      dataPush(dataLayerObject);
    } else {
      this.props.setNextEventLocalStorage({
        event: EVENTS.SUBSCRIBE_WITH_WALLET,
        props: subscribeData,
      });
      registerCommonWallet({ ...data, purchaseRequest: subscribeData });
    }
    setPaymentMethodToLocalStorage(paymentMethod);
  }

  subscribeButton() {
    const { disableSubscription, mobile, wallet, both } = this.state;
    const {
      data: { dark_color },
      t,
    } = this.props;

    var buttonStyle = {
      border: `1px solid ${dark_color}`,
      backgroundColor: dark_color,
      color: "#ffffff",
    };

    //if (currentStep === 2) {
    return (
      <button
        className="btn btn-block mr-1"
        id="subscribe-modal-buy-button"
        style={buttonStyle}
        type="button"
        // disabled={(this.state.isConfirmed === false) || disableSubscription}
        disabled={disableSubscription}
        onClick={() => this.handleSubscribeButtonClick()}
      >
        {t("subscribemodal.subscribe")}
      </button>
    );
    //}
    //return null;
  }

  handleSubscribeButtonClick() {
    const {
      data: { package_id },
      subscribe,
      setPaymentMethodToLocalStorage
    } = this.props;
    const { count, isCompartmentCharge } = this.state;
    this.setState({ disableSubscription: true });
    subscribe({
      packageId: package_id,
      count: count,
      paymentMethod: "MOBILE",
      source: "WEB",
      salesType: "SUBSCRIPTION",
      isCompartmentCharge: isCompartmentCharge,
      customerReferral: sessionStorage.getItem("referral_code"),
    });

    // piwik pro tracking
    let dataLayerObject = {
      tickets_count: this.state.count,
    };
    dataPush(dataLayerObject);
    setPaymentMethodToLocalStorage(PAYMENT_METHOD.MOBILE);
  }

  renderCount(count) {
    const {
      t,
      data: { lottery_price, dark_color },
    } = this.props;
    var style = {
      border: `1px solid ${dark_color}`,
      color: dark_color,
      backgroundColor: "#ffffff",
      selected: "",
    };

    if (this.state.count === count) {
      style.color = "#ffffff";
      style.backgroundColor = dark_color;
      style.selected = " selected";
    }

    return (
      <div key={count} className="col-3 p-1">
        {count === 10 ? (
          <button
            className="btn btn-block mr-1"
            id="subscribe_others_button_id"
            type="button"
            style={style}
            onClick={() => this.setState({ loadEditText: true })}
          >
            <h1>+</h1>
            <small>{t("const_file.other")}</small>
          </button>
        ) : (
          <button
            className={"btn btn-block mr-1" + style.selected}
            id="subscribe-button-id"
            type="button"
            style={style}
            onClick={() => {
              // piwik pro related code segement
              let dataLayerObject = {
                tickets_count: count,
              };
              dataPush(dataLayerObject);

              this.setState({ count });
            }}
          >
            <h1>{count}</h1>
            <small>
              {t("const_file.rs")}
              {count * lottery_price}
            </small>
          </button>
        )}
      </div>
    );
  }

  // Render the count in the first step
  renderCount(count) {
    const {
      t,
      data: { lottery_price, dark_color },
    } = this.props;
    var style = {
      border: `1px solid ${dark_color}`,
      color: dark_color,
      backgroundColor: "#ffffff",
      selected: "",
    };

    if (this.state.count === count) {
      style.color = "#ffffff";
      style.backgroundColor = dark_color;
      style.selected = " selected";
    }

    return (
      <div key={count} className="col-3 p-1">
        {count === 10 ? (
          <button
            className="btn btn-block mr-1"
            id="subscribe_others_button_id"
            type="button"
            style={style}
            onClick={() => this.setState({ loadEditText: true })}
          >
            <h1>+</h1>
            <small>{t("const_file.other")}</small>
          </button>
        ) : (
          <button
            className={"btn btn-block mr-1" + style.selected}
            id="subscribe-button-id"
            type="button"
            style={style}
            onClick={() => {
              // piwik pro related code segement
              let dataLayerObject = {
                tickets_count: count,
              };
              dataPush(dataLayerObject);

              this.setState({ count });
            }}
          >
            <h1>{count}</h1>
            <small>
              {t("const_file.rs")}
              {count * lottery_price}
            </small>
          </button>
        )}
      </div>
    );
  }

  // Render the pay method in the second step
  renderPayMethod() {
    const {
      data: { dark_color },
      t,
    } = this.props;
    const { mobile, wallet, both } = this.state;

    var notSelected = {
      border: `1px solid ${dark_color}`,
      color: dark_color,
      backgroundColor: "#ffffff",
      selected: "",
    };

    var selected = {
      border: `1px solid ${dark_color}`,
      color: "#ffffff",
      backgroundColor: dark_color,
      selected: " selected",
    };

    return (
      <React.Fragment>
        <div key={1} className="col-4 p-1">
          <button
            className={
              "btn btn-block mr-1  p-2 py-3" + (mobile ? " selected" : "")
            }
            type="button"
            style={mobile ? selected : notSelected}
            onClick={() =>
              this.setState({ mobile: !mobile, wallet: false, both: false })
            }
          >
            <h5>{t("subscribemodal.mobile")}</h5>
          </button>
        </div>
        <div key={2} className="col-4 p-1">
          <button
            className={
              "btn btn-block mr-1  p-2 py-3" + (wallet ? " selected" : "")
            }
            type="button"
            style={wallet ? selected : notSelected}
            onClick={() =>
              this.setState({ wallet: !wallet, mobile: false, both: false })
            }
          >
            {/* <h5>{'Wallet'}</h5> */}
            <h5>{t("subscribemodal.credit_debit_card")}</h5>
          </button>
        </div>
        <div key={3} className="col-4 p-1">
          <button
            className={
              "btn btn-block mr-1  p-2 py-3" + (both ? " selected" : "")
            }
            type="button"
            style={both ? selected : notSelected}
            onClick={() =>
              this.setState({ both: !both, mobile: false, wallet: false })
            }
          >
            <h5>{t("subscribemodal.both")}</h5>
          </button>
        </div>
      </React.Fragment>
    );
  }

  // // Render the dayCount in the third step
  // renderSummary(walletPackage) {
  //     const {
  //         t,
  //         data: { dark_color, package_id }
  //     } = this.props;

  //     var style = {
  //         border: `1px solid ${dark_color}`,
  //         color: dark_color,
  //         backgroundColor: "#ffffff",
  //         selected: ""
  //     };

  //     if (this.state.dayCount === walletPackage.periodNumber) {
  //         style.color = "#ffffff";
  //         style.backgroundColor = dark_color;
  //         style.selected = " selected";
  //     }

  //     let periodData = {
  //         lotteryId: package_id,
  //         walletPackageId: walletPackage.packageId,
  //         ticketsCount: this.state.count,
  //         periodNumber: walletPackage.periodNumber
  //     }

  //     return (
  //         <div key={walletPackage.periodNumber} className="col-4 p-1">
  //             <button
  //                 className={"btn btn-block mr-1" + style.selected}
  //                 type="button"
  //                 style={style}
  //                 onClick={() => this.handlePeriodClick(periodData)}
  //             >
  //                 {walletPackage.packageName}
  //                 {/* <small>
  //                     {t("const_file.rs")}
  //                     {dayCount * lottery_price}
  //                 </small> */}
  //             </button>
  //         </div>
  //    );
  // }

  updateInput(event) {
    event.preventDefault();
    // this.setState({mobile_pin: event.target.value });

    this.setState({ count: event.target.value });

    // piwik pro related code segment
    let dataLayerObject = {
      tickets_count: event.target.value,
    };
    dataPush(dataLayerObject);

    // if (!regex.test(event.target.value)) {
    //     this.setState({ count: 1 });
    //     // ReactDOM.findDOMNode(this.refs.ticket_count_sub).value = 1;
    // }

    //     if (event.target.value <= 0) {
    //         if (event.target.value !== "") {
    //             this.setState({ count: 1 });
    //             // ReactDOM.findDOMNode(this.refs.ticket_count_sub).value = 1;
    //         }
    //     } else {
    //         this.setState({ count: event.target.value });
    //     }
  }

  renderEditText() {
    const { t } = this.props;
    return (
      <div className="col-12 p-1">
        {
          <input
            type="number"
            ref="ticket_count_sub"
            min="1"
            pattern="[0-9.]+"
            className="form-control"
            placeholder={t("subscribemodal.enter_no_tickets_buy")}
            value={this.state.count}
            onChange={this.updateInput}
          />
        }
      </div>
    );
  }

  renderPackageTable() {
    const { packagePrice, data, t } = this.props;

    return (
      <table className="table table-bordered">
        <thead>
          <tr class="h6">
            <th className="text-center" scope="col">
              {t("day_names_short.Mon")}
            </th>
            <th className="text-center" scope="col">
              {t("day_names_short.Tue")}
            </th>
            <th className="text-center" scope="col">
              {t("day_names_short.Wed")}
            </th>
            <th className="text-center" scope="col">
              {t("day_names_short.Thu")}
            </th>
            <th className="text-center" scope="col">
              {t("day_names_short.Fri")}
            </th>
            <th className="text-center" scope="col">
              {t("day_names_short.Sat")}
            </th>
            <th className="text-center" scope="col">
              {t("day_names_short.Sun")}
            </th>
          </tr>
        </thead>
        {this.state.dayCount && packagePrice ? (
          <tbody>
            {packagePrice.lotteries.map((element) => {
              return element.draws.map((day, index) => {
                return (
                  <tr key={index}>
                    {Object.keys(day).map((i) => {
                      return (
                        <td key={i} className="text-center">
                          {day[i] ? (
                            <FontAwesomeIcon
                              icon={faCheck}
                              className=""
                              style={{ color: data.dark_color }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              });
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </tbody>
        )}
      </table>
    );
  }

  //placeholder={t("subscribemodal.enter_no_tickets_buy")}

  loadLotteryName(lottery_name) {
    const { t } = this.props;
    var pack = _.find(LOTTERY_LIST, { value: lottery_name });

    // return lottery_name;
    return pack === undefined ? lottery_name : t("lottery_tickets." + pack.key);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  // handlePeriodClick = (periodData) => {
  //     this.props.getPackagePrice(periodData);
  //     this.setState({ dayCount: periodData.periodNumber, periodData });
  // }

  // handleConfirmationCheck = () => {
  //     const { isConfirmed } = this.state;
  //     this.setState({
  //       isConfirmed: !isConfirmed
  //     });
  // }

  setPackageTableChecks(walletPackage) {
    const {
      data: { package_id },
    } = this.props;

    let periodData = {
      lotteryId: package_id,
      walletPackageId: walletPackage.packageId,
      ticketsCount: this.state.count,
      periodNumber: walletPackage.periodNumber,
    };

    if (!this.state.periodData) {
      this.props.getPackagePrice(periodData);
      this.setState({ dayCount: periodData.periodNumber, periodData });
    }
  }

  setDropdownChangeValue(paymentMethod) {
    switch (paymentMethod) {
      case "MOBILE":
        this.setState({
          mobile: true,
          wallet: false,
          both: false,
          isCompartmentCharge: 1,
        });
        break;
      case "WALLET":
        this.setState({
          wallet: true,
          mobile: false,
          both: false,
          isCompartmentCharge: 0,
        });
        break;
      case "BOTH":
        this.setState({
          both: true,
          mobile: false,
          wallet: false,
          isCompartmentCharge: 0,
        });
        break;
      // case "WIN_BALANCE":
      //     this.setState({ wallet: true, mobile: false, both: false, isCompartmentCharge: 1 });
      //     break;
    }
  }

  step1() {
    const { currentStep, wallet, mobile, both, count } = this.state;
    // const { walletInfo, walletPackages, packagePrice } = this.props;

    if (currentStep !== 1) {
      return "";
    } else {
      const {
        data: { dark_color, draw_lotteries },
        data,
        t,
        payment_source,
      } = this.props;

      // if(commonWalletInfo && (wallet === false)){
      //     this.setState({ wallet: true, mobile: false, both: false });
      // }

      var date_on_lottery = t("subscribemodal.draw_available");
      var draw_dates = draw_lotteries[0].draw_dates;

      for (var i = 0; i < draw_dates.length; i++) {
        if (draw_dates.length == 1) {
          var search_day = WEEK_DAYS.find((item) => item.id == draw_dates[i]);
          var day_full_name = search_day.id;
          date_on_lottery =
            date_on_lottery + " " + t("day_names." + day_full_name + "");
        } else {
          if (i == Number(draw_dates.length) - Number(1)) {
            //  if (draw_dates.length!=1) {

            var search_day = WEEK_DAYS.find((item) => item.id == draw_dates[i]);
            var day_full_name = search_day.id;
            date_on_lottery =
              date_on_lottery +
              " " +
              t("const_file.and") +
              " " +
              t("day_names." + day_full_name + "") +
              " ";
          } else {
            var search_day = WEEK_DAYS.find((item) => item.id == draw_dates[i]);
            var day_full_name = search_day.id;
            date_on_lottery =
              date_on_lottery +
              " " +
              t("day_names." + day_full_name + "") +
              ",";
          }
        }
      }

      return (
        <React.Fragment>
          {/* <Wizad page={1} color={dark_color} /> */}
          {/* <br /> */}
          <h5>{t("subscribemodal.no_tickets_sub")}</h5>
          <div className="my-3 px-3 row">
            {this.state.loadEditText
              ? this.renderEditText()
              : [1, 2, 5, 10].map((count) => {
                  return this.renderCount(count);
                })}
          </div>
          <h5 className="my-3 bolded px-2 text-center">{date_on_lottery}</h5>
          <br />
          {/* <p className="my-3  text-center">{t("const_file.convenient_fee")}</p> */}
          <h5>{t("subscribemodal.select_pay_method")}</h5>
          <div className="my-3 px-3 row">
            {/* {this.renderPayMethod()} */}
            <select
              disabled={
                payment_source && payment_source === toUpper(INSTRUMENTS.FRIMI)
                  ? true
                  : false
              }
              className="form-control search-dropdown text-center px-0 col-12"
              id="select-payment-method-in-subscribe-modal"
              onChange={(e) => {
                // piwik pro related code segment
                let dataLayerObject = {
                  event: "selectsPaymentMethodInSubModal",
                  paymentMethod: e.target.value,
                  tickets_count: count,
                };
                dataPush(dataLayerObject);

                this.setDropdownChangeValue(e.target.value);
              }}
            >
              <option value="MOBILE" selected={mobile}>{t("subscribemodal.mobile")}</option>
              <option value="WALLET" selected={wallet}>
                {t("subscribemodal.credit_debit_card")}
              </option>
              <option value="BOTH" selected={both}>
                {payment_source === toUpper(INSTRUMENTS.FRIMI)
                  ? "FriMi"
                  : t("subscribemodal.both")}
              </option>
              {/* <option value="WIN_BALANCE">{t("subscribemodal.win_balance")}</option> */}
              {/* {this.renderOptions(values)} */}
            </select>
          </div>

          {mobile ? (
            <>
              {/* onvenient_fee change in mobile view and added info icon */}
              <p style={{ fontSize: 10 }} className="my-3  text-center">
                {/* {t("const_file.convenient_fee")} */}
                {FEE.TEXT}{" "}
              </p>

              <ul style={{ fontSize: 10 }}>
                {Object.values(FEE)
                  .filter((val) => val != FEE.TEXT)
                  .map((val) => (
                    <li>{val}</li>
                  ))}
              </ul>
            </>
          )  : (
            ""
          )}

          {wallet ? (
            <p style={{ fontSize: 12 }} className="my-3  text-center">{`${t(
              "const_file.minimum_topup_front"
            )}${MINIMUM_TOPUP_FEE}${t("const_file.minimum_topup_end")}`}</p>
          ) : (
            ""
          )}

          {/* {both ? (
            <p style={{ fontSize: 12 }} className="my-3  text-center">
              {" "}
              {`${t("const_file.both_fee_front")}${MINIMUM_TOPUP_FEE}${t(
                "const_file.both_fee_end"
              )}`}{" "}
            </p>
          ) : (
            ""
          )} */}

          <br />
          <div className="my-3 px-3 row">
            <div className="col-6">{t("subscribemodal.total_tickets")}</div>
            <div className="col-6 text-right">{count ? count : "0"}</div>
          </div>
          <div className="my-3 px-3 row">
            <div className="col-6">
              {t("subscribemodal.total_tickets_price")}
            </div>
            <div className="col-6 text-right">
              {count * data.lottery_price}.00 LKR
            </div>
          </div>
          <br />
          {/* <h5>{t("subscribemodal.confirmation")}</h5>
                    <div className="my-3 px-3 row">
                        <label><input type="checkbox" onChange={this.handleConfirmationCheck} />
                        &nbsp;{t("subscribemodal.charge_500_confirmation")} </label>
                    </div>   */}
          {mobile && !wallet ? this.subscribeButton() : this.submitButton()}
          {/* {this.submitButton()} */}
          {/* <br />

                    <div className="row">
                        <div className="col-6 pr-1">
                            {this.submitButton()}
                        </div>
                    </div> */}
        </React.Fragment>
      );
    }
  }

  step2() {
    const { currentStep, wallet, mobile, both } = this.state;
    const { data, t } = this.props;

    if (currentStep !== 2) {
      return null;
    }
    return (
      <React.Fragment>
        <Wizad page={2} color={data.dark_color} />
        <br />

        <h5>{t("subscribemodal.select_pay_method")}</h5>
        <div className="my-3 px-3 row pt-2">{this.renderPayMethod()}</div>

        {mobile ? (
                <p style={{ fontSize: 12 }} className="my-3  text-center">
                  {" "}
                  {`${t(
                    "const_file.minimum_topup_front"
                  )}${MINIMUM_TOPUP_FEE}${t(
                    "const_file.minimum_topup_end"
                  )}`}{" "}
                </p>
              )  : (
          ""
        )}

        {wallet ? (
          <p style={{ fontSize: 12 }} className="my-3  text-center">{`${t(
            "const_file.minimum_topup_front"
          )}${MINIMUM_TOPUP_FEE}${t("const_file.minimum_topup_end")}`}</p>
        ) : (
          ""
        )}

        {both ? (
          <p style={{ fontSize: 12 }} className="my-3  text-center">
            {" "}
            {`${t("const_file.both_fee_front")}${MINIMUM_TOPUP_FEE}${t(
              "const_file.both_fee_end"
            )}`}{" "}
          </p>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-6 pr-1">{this.previousButton()}</div>
          <div className="col-6 pr-1">
            {mobile && !wallet ? this.subscribeButton() : this.nextButton()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  step3() {
    const { currentStep, dayCount } = this.state;
    const { walletPackages, packagePrice, data, t } = this.props;

    if (currentStep !== 3) {
      return null;
    }

    return (
      <React.Fragment>
        <Wizad page={3} color={data.dark_color} />
        <br />
        <div className="my-3 px-3 row">
          {t("subscribemodal.tickets_on_below_dates")}
        </div>
        <div className="my-3 px-3 row table-responsive">
          {this.renderPackageTable()}
          {this.setPackageTableChecks(walletPackages[0])}
        </div>
        <div className="my-3 px-3 row">
          <div className="col-6">{t("subscribemodal.total_tickets")}</div>
          <div className="col-6 text-right">
            {packagePrice.ticketsCount && dayCount
              ? packagePrice.ticketsCount
              : "0"}
          </div>
        </div>
        <div className="my-3 px-3 row">
          <div className="col-6">{t("subscribemodal.total_tickets_price")}</div>
          <div className="col-6 text-right">
            {packagePrice.price && dayCount ? packagePrice.price : "0"}.00 LKR
          </div>
        </div>
        <br />
        <h5>{t("subscribemodal.confirmation")}</h5>
        <div className="my-3 px-3 row">
          <label>
            <input type="checkbox" onChange={this.handleConfirmationCheck} />
            &nbsp;{t("subscribemodal.charge_500_confirmation")}{" "}
          </label>
        </div>
        {/* defaultChecked={this.state.isConfirmed} */}
        <br />

        <div className="row">
          <div className="col-6 pr-1">{this.previousButton()}</div>
          <div className="col-6 pr-1">{this.submitButton()}</div>
        </div>
      </React.Fragment>

      // <React.Fragment>
      //     <Wizad page={3} color={data.dark_color} />
      //     <br />
      //     <h5>{t("subscribemodal.select_package")}</h5>
      //     <div className="my-3 px-3 row">

      //         {walletPackages.map(walletPackage => {
      //             return this.renderSummary(walletPackage);
      //         })}

      //     </div>
      //     <div className="my-3 px-3 row">
      //         {t("subscribemodal.tickets_on_below_dates")}
      //     </div>
      //     <div className="my-3 px-3 row table-responsive">
      //         {this.renderPackageTable()}
      //     </div>
      //     <div className="my-3 px-3 row">
      //         <div className='col-6'>{t("subscribemodal.total_tickets")}</div>
      //         <div className='col-6 text-right'>{(packagePrice.ticketsCount && dayCount) ? packagePrice.ticketsCount : '0'}</div>
      //     </div>
      //     <div className="my-3 px-3 row">
      //         <div className='col-6'>{t("subscribemodal.total_tickets_price")}</div>
      //         <div className='col-6 text-right'>{(packagePrice.price && dayCount) ? packagePrice.price : '0'}.00 LKR</div>
      //     </div>
      //     <br />

      //     <div className="row">
      //         <div className="col-6 pr-1">
      //             {this.previousButton()}
      //         </div>
      //         <div className="col-6 pr-1">
      //             {this.submitButton()}
      //         </div>
      //     </div>
      // </React.Fragment>
    );
  }

  closeSubModal() {
    const {
      closeDialog,
      clearNextEventLocalStorage,
      clearNextEvent,
    } = this.props;

    closeDialog(MODALS.SUB);
    clearNextEventLocalStorage();
    clearNextEvent();
  }

  render() {
    const { data, t } = this.props;

    return (
      <div className="p-3">
        <div className="modal-header">
          <h5 className="modal-title">
            {t("subscribemodal.subscribe")}{" "}
            {t(this.loadLotteryName(data.lottery_name))}
          </h5>
          <button
            type="button"
            className="close"
            onClick={() => this.closeSubModal()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <LoadingBar
          scope="loading_search"
          maxProgress={100}
          style={{ backgroundColor: data.dark_color }}
        />
        <div className="modal-body subcription-modal">
          <React.Fragment>
            <form>
              {this.step1()}
              {this.step2()}
              {this.step3()}
            </form>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      subscribe,
      closeDialog,
      getPackage,
      getPackagePrice,
      subscribeWithWallet,
      getCommonWalletInfo,
      registerCommonWallet,
      setNextEventLocalStorage,
      clearNextEventLocalStorage,
      clearNextEvent,
      getPaymentSource,
      setPaymentMethodToLocalStorage,
      getPaymentMethodFromLocalStorage
    },
    dispatch
  );
}

function mapStateToProps({
  walletPackages,
  packagePrice,
  commonWalletInfo,
  payment_source,
  payment_method
}) {
  return { walletPackages, packagePrice, commonWalletInfo, payment_source, payment_method };
}

export default compose(
  translate,
  reduxDialog({ name: MODALS.SUB, centered: true, backdrop: "static" }),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SubModal);
