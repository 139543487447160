/*
 *  DIGITALX LABS(PVT)LTD PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * 
 *  Copyright © 2019. DIGITALX LABS(PVT)LTD
 *  All Rights Reserved.
 * 
 *  NOTICE:  All information contained herein is, and remains
 *  the property of DIGITALX LABS(PVT)LTD.  The intellectual and technical concepts contained
 *  herein are proprietary to DIGITALX LABS(PVT)LTD.
 *  Dissemination of this information, reproduction of this material, and copying or distribution of this software
 *  is strictly forbidden unless prior written permission is obtained from DIGITALX LABS(PVT)LTD.
 */

import React, { Component } from 'react';
import { PhoneCall as PhoneIcon } from 'react-feather';
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  GooglePlusIcon,
  LinkedinIcon,
  WhatsappIcon,
  LivejournalIcon,
  EmailIcon
} from 'react-share';
import { connect } from "react-redux";
import {
  translate,
  getLanguage
} from 'react-switch-lang';

import logo from '../images/lucky_footer_logo.png';
const shareUrl = "http://lucky1.lk/";
const title = "Buy Online Lottery Tickets in Sri Lanka";


class Footer extends Component {
  render() {
    const { t } = this.props;
    var year = new Date().getFullYear();
    return (
      <div className="d-flex flex-column align-items-center mt-2 footer-sec">
        <div className="p-2 pt-4">
          <img className="footer-image" src={logo} alt="NLB Logo" />
        </div>


        <div className="p-2 text-primary">
          <h1>
            <PhoneIcon /> <a href = "tel: +94-117-989-989"> 0117 989 989 </a>
            </h1>
        </div>

        <div className="p-2 my-3">
          <ul className="nav flex-column flex-md-row text-center">
            <li className="nav-item">
              <a className="nav-link" href={t("footer.about_link")} target="_blank" rel="noopener noreferrer">{t("footer.about")}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={t("footer.howitwork_link")} target="_blank" rel="noopener noreferrer">{t("footer.howitwork")}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={t("footer.terms_link")} target="_blank" rel="noopener noreferrer">{t("footer.terms")}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={t("footer.privacypolicy_link")} target="_blank" rel="noopener noreferrer">{t("footer.privacypolicy")}</a>
            </li>
          </ul>
        </div>
        <div className="Demo__container">

          <div className="Demo__some-network_facebook" >
            <FacebookShareButton
              url={shareUrl}
              quote={title}
              className="Demo__some-network__share-button">
              <FacebookIcon
                size={32}
                round />
            </FacebookShareButton>
          </div>

          <div className="Demo__some-network">
            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="Demo__some-network__share-button">
              <TwitterIcon
                size={32}
                round />
            </TwitterShareButton>

            <div className="Demo__some-network__share-count">
              &nbsp;
          </div>
          </div>

          <div className="Demo__some-network">
            <WhatsappShareButton
              url={shareUrl}
              title={title}
              separator=":: "
              className="Demo__some-network__share-button">
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <div className="Demo__some-network__share-count">
              &nbsp;
          </div>
          </div>

          <div className="Demo__some-network">
            <GooglePlusShareButton
              url={shareUrl}
              className="Demo__some-network__share-button">
              <GooglePlusIcon
                size={32}
                round />
            </GooglePlusShareButton>

          </div>

          <div className="Demo__some-network">
            <LinkedinShareButton
              url={shareUrl}
              title={title}
              windowWidth={750}
              windowHeight={600}
              className="Demo__some-network__share-button">
              <LinkedinIcon
                size={32}
                round />
            </LinkedinShareButton>

          </div>

          <div className="Demo__some-network">
            <EmailShareButton
              url={shareUrl}
              subject={title}
              body="body"
              className="Demo__some-network__share-button">
              <EmailIcon
                size={32}
                round />
            </EmailShareButton>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 text-center text-lg-left">
              <p>Lucky1 &copy; {year}. All Rights Reserved.</p>
            </div>
            <div className="col-12 col-lg-6 text-center text-lg-right">
              <p>A solution by <a href="https://digitalxlabs.com/" target="_blank" rel="noopener noreferrer">DigitalX (Pvt) Ltd.</a></p>
            </div>
          </div>
        </div>


        {/* <div className="p-2 text-primary text-center">
                    <p>
                        Lucky 1 &copy; 2019. All Rights Reserved.<br />
                        A solution by <a href="http://www.zmessenger.com" target="_blank" rel="noopener noreferrer">zmessenger (Pvt) Ltd.</a>
                    </p>
                </div> */}
      </div>
    );
  }
}

export default translate(connect()(Footer));
